import {Component, EventEmitter, Input, OnInit, Output, OnDestroy, ChangeDetectionStrategy, OnChanges} from '@angular/core';
import {TilesService} from '../../services/word-filter-tiles.service';
import {map, take} from 'rxjs/operators';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ActivatedRoute} from '@angular/router';
import { UtilityService } from '../../services/utility.service';
import {DomSanitizer} from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ObserversModule } from '@angular/cdk/observers';
import { AsyncPipe } from '@angular/common';
import { TilesUpdatingOptionEnum } from './course-workbook-edit.component';
@Component({
  selector: 'app-course-workbook-edit-tiles',
  templateUrl: './html/course-workbook-edit-tiles.html',
  styles: [
    `
      .cdk-drag-disabled {
        cursor: default;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CourseWorkbookEditTilesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() set setTiles(tiles: any) {
    // if (tiles?.length) {
      this.tilesSubject.next(tiles);
    // }
    // if((this.tiles.length === 0) && (tiles.length > 0)){
    //   this.tiles = tiles;
    //   this.init();
    // } else if((this.tiles.length > 0) && (tiles.length > 0)){
    //   this.tiles = tiles;
    // } else if(tiles.length === 0){
    //   return;
    // }
  }
  @Input() set setTileType(tileType: string) {
    if (tileType) {
      this.tileTypeSubject.next(tileType);

    }
  }
  @Input() set setLessonPlanStep(lessonPlanStep: any) {
    if (lessonPlanStep) {
      this.lessonPlanStepSubject = new BehaviorSubject<any>(lessonPlanStep);
      this.lessonPlanStep$ = this.lessonPlanStepSubject.asObservable();
      this.lessonPlanStepSubject.next(lessonPlanStep);
      if (!this.isInitialized) {
        this.init();
      }
    }
  }

  @Input() set setTilesSettings(tileSettings: any) {
    if (tileSettings) {
      this.tilesSettingsSubject = new BehaviorSubject<any>(tileSettings);
      this.tilesSettings$ = this.tilesSettingsSubject.asObservable();
      this.tilesSettingsSubject.next(tileSettings);
    }
  }
  @Input() isBlankTiles = false;
  @Input() fromTab = false;

  @Output() updateLessonPlanStepEvent: EventEmitter<any> = new EventEmitter<any>();
  private isInitialized = false;
  inDropzone = false;
  private  currentDraggingTileIndex: any;
  private subscriptions = new Subscription();
  public isDragging = false;
  public availableTiles = [
    'green',
    'blue',
    'orange',
    'red',
    'yellow',
    'peach',
    'pink',
    'gray',
    'lightpurple',
    'lightblue',
    'lightgreen',
    'lightyellow',
    'purple',
    'cream'
  ];
  public tiles: any[] = [];
  public tileType = 'tiles';
  public tileBank: any[] = [];
  public currentTileBankTiles: any = [];
  public lessonPlanStep: any = null;
  public tilesSettings: any = null;

  private tilesSettingsSubject: any;
  private lessonPlanStepSubject: any;
  private tilesSubject: any = new BehaviorSubject<any>(this.tiles);
  private currentTileBankTilesSubject: any = new BehaviorSubject<any>(this.currentTileBankTiles);
  private tileBankSubject: any = new BehaviorSubject<any>(this.tileBank);
  private tileTypeSubject: any = new BehaviorSubject<any>(this.tileType);

  public tilesSettings$: any;
  public lessonPlanStep$: any;
  public currentTileBankTiles$ = this.currentTileBankTilesSubject.asObservable();
  public tileType$ = this.tileTypeSubject.asObservable();
  public tiles$ = this.tilesSubject.asObservable().pipe(map((tiles: any) => {
    if (tiles?.length) {
      let result = tiles.filter(item => item);
      result = [...result, ''];
      return result;
    } else {
      return [];
    }
  }));
  public tileBank$ = this.tileBankSubject.asObservable().pipe(map((tiles: any) => {
    return tiles.filter(item => item.key !== 'sight');
  }));
  private currentDraggingTileClass: string;
  isCloseMoreTiles = true;
  tileUpdating: TilesUpdatingOptionEnum;

  constructor(
    private tilesService: TilesService,
    private utiltiyService: UtilityService,
    private route: ActivatedRoute,
    private sanitized: DomSanitizer,
    private async: AsyncPipe
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  ngOnChanges(changes: any) {
    // if (changes.setTileType) {
    //   this.tileType = changes.setTileType.currentValue;
    //   if ((this.tiles.length === 0) && (changes.setTiles.currentValue.length > 0)) {
    //     this.tiles = changes.setTiles.currentValue;
    //     this.init();
    //   } else if ((this.tiles.length > 0) && (changes.setTiles.currentValue.length > 0)) {
    //     this.tiles = changes.setTiles.currentValue;
    //   } else if (changes.setTiles.currentValue.length === 0) {
    //     return;
    //   }

    // }
  }
  init() {
    this.isInitialized = true;
    const lessonPlanStep: any = this.async.transform(this.lessonPlanStep$);

    let tileType: any = this.async.transform(this.tileType$);
    let tiles: any = tileType === 'miniTiles' ? lessonPlanStep.miniTiles : this.async.transform(this.tiles$);
    if (tileType === 'tiles') {
      tiles = lessonPlanStep.tiles;
    }

    if (!Array.isArray(tiles)) {
      tiles = [];
    }
    if (tileType === 'miniTiles' && (!tiles || tiles.length === 0)) {
      // tiles = [
      //   'tiles.bv.a',
      //   'tiles.bv.e',
      //   'tiles.bv.i',
      //   'tiles.bv.o',
      //   'tiles.bv.u',
      //   'tiles.bc.b',
      //   'tiles.bc.c',
      // ];
      tiles = [];

    } else if (this.isBlankTiles && (!tiles || tiles.length === 0)) {
      tiles = [
        'tiles.blank.red',
        'tiles.blank.yellow',
        'tiles.blank.green',
        'tiles.blank.blue',
        'tiles.blank.orange'
      ];
      // this.updateLessonPlanStep();

    } else if (!tiles || tiles.length === 0) {
      tiles = [
        // 'tiles.bv.a',
        // 'tiles.bv.e',
        // 'tiles.bv.i',
        // 'tiles.bv.o',
        // 'tiles.bv.u'
      ];
      // this.updateLessonPlanStep();

    }
    tiles = tiles.filter((tile) => tile !== '');

    tiles = tiles.flat().filter(tile => tile !== "'+tilekey+'");
    this.tilesSubject.next(tiles);

    // this.updateLessonPlanStep();
this.subscriptions.add(
  this.tilesService
  .getAllTiles()
  .pipe(
    take(1)
  )
  .subscribe((allTiles: any[]) => {
    const blanktiles = ['tiles.blank.pink',
    'tiles.blank.purple',
    'tiles.blank.lightpurple',
    'tiles.blank.lightblue',
    'tiles.blank.lightgreen',
    'tiles.blank.lightyellow',
    'tiles.blank.peach',
    'tile.blank.cream',
    'tile.blank.gray',
    'tiles.blank.red',
    'tiles.blank.yellow',
    'tiles.blank.green',
    'tiles.blank.blue',
    'tiles.blank.orange'

  ];
    this.tileBankSubject.next(allTiles) ;
    let currentTileBankTiles = this.isBlankTiles
      // ? allTiles.find(tileType => tileType.name.toLowerCase() === 'blank tiles')
      ? blanktiles
      : allTiles[0];
    if (this.isBlankTiles) {
      currentTileBankTiles.tiles = currentTileBankTiles.filter(tile => {
        const tileVal = tile.substring(tile.lastIndexOf('.') + 1);
        return this.availableTiles.indexOf(tileVal) >= 0 && !tiles.find(lessonPlanTile => lessonPlanTile.indexOf(tileVal) >= 0);
      });
    }
    this.currentTileBankTilesSubject.next(currentTileBankTiles);
    this.updateLessonPlanStep();

  })

);

  }
  ngOnInit(): void {
    // this.init();
  }
  returnLetters(letters, index) {
    if (letters === null) {
      return ;
    }
    if (letters.split('.')[1] === 'blank') {
        return '';
    } else if (letters === 'tiles.sym.4') {
      return '.';
    }
    letters = letters.substring(letters.lastIndexOf('.') + 1);

    if (letters === 'period' ) {
      return '.';
    }
    // if (this.lowerCase || index > 0 || this.lowerCase === undefined) {
    //   return letters;
    // } else {
    //   return letters.charAt(0).toUpperCase() + letters.slice(1);
    // }
    return letters;
  }

  getBlankTileClass(tile) {
    let tilesSettings: any = this.async.transform(this.tilesSettings$);
    return tilesSettings['blank'][tile.substring(tile.lastIndexOf('.') + 1)].tilebgcolor;
  }
  getTileClass(tile) {
    if (!tile) {
      return ' ';
    }
    let tilesSettings: any = this.async.transform(this.tilesSettings$);
    return tilesSettings[tile.substring(tile.indexOf('.') + 1, tile.lastIndexOf('.'))].tilebgcolor
    + ' ' +
    tilesSettings[tile.substring(tile.indexOf('.') + 1, tile.lastIndexOf('.'))]?.tilefontcolor;

  }
  reorderTiles($event: CdkDragDrop<any, any>) {
    const currentTileType = $event.container.element.nativeElement.id;
    const previousTileType = $event.previousContainer.element.nativeElement.id;

    // if ($event.previousContainer.data >= $event)
    let tiles: any = this.async.transform(this.tiles$);
    tiles = tiles.slice();

    let currentTileBankTiles: any = this.async.transform(this.currentTileBankTiles$);

    // because of bugs with cdkDragable and multiline horizontal lists, the two lists here are comprised of a draggableGroup, meaning that each
    // individual item in the list is its own droppable list. Because of this I need to use the container id's to tell which tile belongs to which list
    // and decide what action to take depending on where that tile is dropped
    // if a tilebank tile is moved into the lesson plan tiles, simply add that tile to the lessonPlan tiles list
    if (previousTileType === 'tileBank' && currentTileType === 'lessonPlanTiles-top') {
      tiles.splice($event.container.data, 0, currentTileBankTiles.tiles[$event.previousContainer.data]);
      if (this.isBlankTiles) {
        currentTileBankTiles.tiles.splice($event.previousContainer.data, 1);
      }
    }

    // if a tile is moved within the lesson plan tiles reorder the array
    if (previousTileType === 'lessonPlanTiles-bottom' && currentTileType === 'lessonPlanTiles-top') {
      const movedTile = currentTileBankTiles.tiles[$event.previousContainer.data];
      const newIndex = $event.container.data;
      tiles.splice(newIndex, 0, movedTile);
    }

    // if it is empty
    if (currentTileType === 'empty-top') {
      tiles.push(currentTileBankTiles.tiles[$event.previousContainer.data]);
      if (this.isBlankTiles) {
        currentTileBankTiles.tiles.splice($event.previousContainer.data, 1);

      }
    }


    if (previousTileType === 'lessonPlanTiles-top' && currentTileType === 'lessonPlanTiles-top') {
      const sourceIndex = $event.previousContainer.data;
      const targetIndex = $event.container.data;

      const tempArray = tiles.filter((_, i) => i !== sourceIndex && i !== (tiles.length - 1));
      tempArray.splice(targetIndex, 0, tiles[sourceIndex]);
      tiles = tempArray;
    }

    this.tilesSubject.next(tiles);
    this.currentTileBankTilesSubject.next(currentTileBankTiles);

    this.updateLessonPlanStep();
  }

  sort(tileSet: any[]) {
    if (!tileSet) {
      return;
    }

    return tileSet.sort((a, b) => {
      if (a < b) {
        return -1;
      }

      if (a > b) {
       return 1;
      }

      return 0;
    });
  }

  returnTiles($event: any) {
    this.currentDraggingTileIndex = null;
    const currentTileType = $event.container.element.nativeElement.id;
    const previousTileType = $event.previousContainer.element.nativeElement.id;
    let currentTileBankTiles: any = this.async.transform(this.currentTileBankTiles$);
    let tiles: any = this.async.transform(this.tiles$);
    tiles = [...(tiles || [])];
    if (previousTileType === 'lessonPlanTiles-top' && (currentTileType === 'tileBank' || currentTileType === 'lessonPlanTiles-bottom')) {
      tiles.splice($event.previousContainer.data, 1);
      this.tilesSubject.next(tiles);

      if (this.isBlankTiles) {
        const availableColor = [...$event.previousContainer.element.nativeElement.children[0].classList]
          .find(className => className.indexOf('c-') === 0)
          .substring(2);
          currentTileBankTiles.tiles.splice(this.availableTiles.indexOf(availableColor), 0, `tiles.blank.${availableColor}`);
        this.currentTileBankTilesSubject.next(currentTileBankTiles);
      }
      this.updateLessonPlanStep();
    }
  }

  tilesSetChanged($event: any) {
    let tileBank: any = this.async.transform(this.tileBank$);
    this.currentTileBankTilesSubject.next(tileBank.find(tileSet => tileSet.key === $event));
  }

  updateLessonPlanStep() {
    let tileType: any = this.async.transform(this.tileType$);
    let tiles: any = this.async.transform(this.tiles$);
    let lessonPlanStep: any = this.async.transform(this.lessonPlanStep$);
    lessonPlanStep = {...{}, ...lessonPlanStep};
    if (tileType === 'blankTiles' || tileType === 'miniTiles') {
      lessonPlanStep[tileType] = tiles;
    } else {
      lessonPlanStep.tiles = tiles;
    }
    this.updateLessonPlanStepEvent.emit(lessonPlanStep);
  }

  removeTiles($event: any) {
    const test = $event;
  }

  dropped() {
    let currentTileBankTiles: any = this.async.transform(this.currentTileBankTiles$);
    let tiles: any = this.async.transform(this.tiles$);
    if (this.inDropzone && this.currentDraggingTileIndex && this.isDragging) {
      // remove current tile
      tiles.splice(this.currentDraggingTileIndex.data, 1);
      this.tilesSubject.next(tiles);
      if (this.isBlankTiles) {
        const availableColor = this.currentDraggingTileClass.substring(2);
        currentTileBankTiles.tiles.splice(this.availableTiles.indexOf(availableColor), 0, `tiles.blank.${availableColor}`);
        this.currentTileBankTilesSubject.next(currentTileBankTiles);
      }
      this.currentDraggingTileIndex = null;
      this.updateLessonPlanStep();
      this.isDragging = false;
    }
  }

  setDraggingTile($event: any) {
    this.isDragging = true;
    this.currentDraggingTileClass = [...$event.source.element.nativeElement.classList].find(className => className.indexOf('c-') === 0);
    this.currentDraggingTileIndex = $event.source.dropContainer;
  }

  endDrag() {
    // this.isDragging = false;
  }


  isDisabled(val) {
    let tiles: any = this.async.transform(this.tiles$);
    if (tiles?.length) {
      return tiles.indexOf(val) >= 0;
    }
  }

  sortDroppedTiles() {
    let tiles: any = this.async.transform(this.tiles$);
    this.subscriptions.add(
      this.utiltiyService
      .sortDroppedTiles(tiles)
      .pipe(take(1))
      .subscribe((sortedTiles) => {
        this.tilesSubject.next(sortedTiles);
        this.updateLessonPlanStep();
      })
    );
  }

  clearTiles() {
    this.tilesSubject.next([]);
    this.updateLessonPlanStep();
  }

  resetBlankTiles() {
    let currentTileBankTiles: any = this.async.transform(this.currentTileBankTiles$);
    let tiles: any = this.async.transform(this.tiles$);
    currentTileBankTiles.tiles = [
      ...(currentTileBankTiles.tiles || []), ...(tiles || [])
    ];
    this.currentTileBankTilesSubject.next(currentTileBankTiles);
    this.clearTiles();
  }

  populateBlankTileHTML() {
    return this.sanitized.bypassSecurityTrustHtml(`<span style='visibility: hidden'>~</span>`);
  }

  toggleMoreTiles() {

    this.isCloseMoreTiles = !this.isCloseMoreTiles;
    /*this.lessonPlanStep$.pipe(take(1)).subscribe(lessonPlanStep => {
      if (
        activityTypeId === 'blankTiles' ||
        (
        lessonPlanStep.tileUpdating &&
        lessonPlanStep.tileUpdating !== TilesUpdatingOptionEnum.ActiveWordsBased &&
        lessonPlanStep.tileUpdating !== TilesUpdatingOptionEnum.ExactTilesFilterBased &&
        lessonPlanStep.tileUpdating !== TilesUpdatingOptionEnum.TilesFromTileLayout
        )
      ) {
        this.isCloseMoreTiles = !this.isCloseMoreTiles;
      }
    });*/
  }

  checkMoreTilesDisabled() {
    return this.lessonPlanStep$.pipe(take(1))
      .pipe(map((lessonPlanStep: any) => {

        if (
          lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.ActiveWordsBased ||
          lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.ExactTilesFilterBased ||
          lessonPlanStep.tileUpdating === TilesUpdatingOptionEnum.TilesFromTileLayout
        ) {
          return true;
        } else {
          return false;
        }
      }));
  }
}
