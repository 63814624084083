import { AllPhrasesResolver } from './resolvers/all-phrases.resolver';
import { SettingsResolver } from './resolvers/settings.resolver';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import * as fromWhizzimo from './store';
import {HeaderComponent} from './components/header/header.component';
import { AsyncPipe, DatePipe } from '@angular/common';

import {
  CourseWorkBooksComponent,
  CourseDialogComponent,
  CourseWorkbookMenuComponent, CourseWorkbookSettingsMenuComponent
} from './components/workbooks/course-workbooks.component';

import {AddWorkBooksComponent, AddWorkbookDialogComponent} from './components/workbooks/add-workbooks.component';

import {SupportComponent, TutorialButtonComponent, TutorialDialogComponent} from './components/support/support.component';

import {ValidationComponent} from './components/validation.component';


import {WorkbookLibraryComponent, WorkbookLibraryMenuComponent} from './components/workbooks/workbook-library.component';

import {TileTextPipe} from './pipes/tile.pipe';

import {ApiService} from './services/api.service';
import {StripeService} from './services/stripe.service';
import {WordlistService} from './services/wordlist.service';
import {UserService} from './services/user.service';
import {CustomHttpInterceptor} from './services/interceptor.service';
import { SettingsService } from './services/new-settings.service';
import {RouterModule} from '@angular/router';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MaterialModule } from './material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {WorkbookMenuComponent, WorkBooksComponent} from './components/workbooks/workbooks.component';
import {WorkbookCardsComponent, CreateWorkbookOptionsComponent} from './components/workbooks/workbook-cards.component';
import {WordBottomSheetComponent} from './components/workbooks/workbook-builder/words.component';
import {TilePairingModalComponent} from './components/workbooks/workbook-builder/word-filters/word-filter-tiles.component';
import {LogOutModalComponent} from '../app/app.component';
import {SelectPlanModalComponent} from '../app/app.component';
import {DemoModalComponent} from '../app/app.component';
import { ColorMenuComponent } from '../activities/activity-header/color-menu/color-menu.component';
import {ActivityTypeService} from './services/activity-type.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CallbackPipe} from './pipes/callback.pipe';
import { SafePipe } from '../core/pipes/sanitize.pipe';
import {SortPipe} from './pipes/sort.pipe';
import {HeaderWorkbookBuilderComponent} from './components/header/header-workbook-builder.component';
import {HeaderSettingsComponent} from './components/header/header-settings.component';
import {HeaderActivitiesComponent} from './components/header/header-activities.component';
import {DndModule} from 'ng2-dnd';
import {AddCardMenuComponent, SamePlanMenuComponent, SubscriptionMenuComponent} from '../Settings/Account/account.component';
import {AddCardComponent} from '../Settings/Account/add-card.component';
import {SelectPlanComponent} from '../Settings/Account/select-plan.component';
import {SuccessfulComponent} from '../Settings/Account/successful.component';
import {CancelSubscriptionComponent} from './components/cancel-subscription.component';
import {TileBankComponent} from './components/tile-bank/tile-bank.component';
import {SpellingAreaComponent} from './components/spelling/spelling-area.component';
import {AngularDraggableModule} from 'angular2-draggable';
import {BumpingTilesComponent} from './components/bumping-tiles/bumping-tiles.component';
import {HeaderLessonPlansComponent} from './components/header/header-lesson-plans.component';
import {LessonPlanStepsMenuComponent} from '../activities/side-navs/lesson-plan-steps-menu.component';
import {LessonPlansMenuComponent} from '../activities/side-navs/lesson-plans-menu.component';
import { ManageCourseDialogComponent } from './components/manage-course-dialog/manage-course-dialog.component';
import { AddCourseDialogComponent } from './components/add-course-dialog/add-course-dialog.component';
import { ViewWordsDialogComponent } from './components/view-words-dialog/view-words-dialog.component';
import {CourseWorkbookEditMenuComponent} from '../activities/activity-header/course-workbook-edit-menu.component';
import {CourseWorkbookEditModule} from './components/course-workbook-edit/course-workbook-edit.module';
import {CourseWorkbookEditComponent} from './components/course-workbook-edit/course-workbook-edit.component';
import {CourseWorkbookEditWordsComponent} from './components/course-workbook-edit/course-workbook-edit-words.component';
import {CourseWorkbookEditTilesComponent} from './components/course-workbook-edit/course-workbook-edit-tiles.component';
import {CourseWorkbookEditWordTilesComponent} from './components/course-workbook-edit/course-workbook-edit-word-tiles.component';
import {CourseWorkbookEditWorkbookItemsComponent} from './components/course-workbook-edit/course-workbook-edit-workbook-items.component';
import {DemoScreenDialogComponent} from './components/demo-screen-dialog/demo-screen-dialog.component';
import { AddSavedSettingsDialogComponent } from './components/add-saved-settings-dialog/add-saved-settings-dialog.component';

import {ScrollingModule} from '@angular/cdk/scrolling';

import { LessonPlanCardsComponent } from './components/lesson-plans/lesson-plan-card';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { CourseListDialogComponent } from './components/workbooks/course-list-dialog.component';


import { DragDropCardsComponent } from './components/drag-drop-cards/drag-drop-cards.component';
import { SettingsHeaderComponent } from './components/settings-header/settings-header.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {TileComponent} from './components/tile.component';
import { AddCollectionDialogComponent } from './components/add-collection-dialog/add-collection-dialog.component';
import { AddImportCollectionDialogComponent } from './components/add-import-collection-dialog/add-import-collection-dialog.component';
import { DeleteCollectionDialogComponent } from './components/delete-collection-dialog/delete-collection-dialog.component';

import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { LessonPlanWarningDialogComponent } from './components/lesson-plan-warning-dialog/lesson-plan-warning-dialog.component';
import { ViewSharingListDialogComponent } from './components/view-sharing-list-dialog/view-sharing-list-dialog.component';
import {ActivityService} from './services/activity.service';
import {SimultaneousLoginDialogComponent} from './components/simultaneous-login-dialog/simultaneous-login-dialog.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DummyDataEffect } from './store/dummy/dummy.effect';
import { PlanEffect } from './store/plan/plan.effect';
import { CoursesEffect } from './store/courses/courses.effect';
import { SubscriptionEffect } from './store/subscriptions/subscriptions.effect';
import { CourseWorkbookEffect } from './store/course-workbook/course-workbook.effect';
import { AllCoursesResolver } from './resolvers/all-courses.resolver';
import { AllSubscribedCoursesResolver } from './resolvers/all-subscribed-courses.resolver';
import { AllMyLibraryWorkbooksResolver } from './resolvers/all-my-library-workbooks.resolver';
import { WorkbooksEffect } from './store/workbook/workbooks.effect';
import { MainCourseResolver } from './resolvers/main-course.resolver';
import { MainCourseWorkbooksResolver } from './resolvers/main-course-workbooks.resolver';
import { WordsEffect } from './store/words/words.effect';
import { LessonPlansEffect } from './store/lesson/lesson.effect';
import { EditLessonPlansEffect } from './store/edit-lesson/edit-lesson.effect';
import { SetCourseEffect } from './store/set-course/set-course.effect';
import { SetttingsEffect } from './store/settings/settings.effect';
import { ActivityEffect } from './store/activity/activity.effect';
import { AccountEffect } from './store/account/account.effect';
import { SentencesEffect } from './store/sentence/sentence.effect';
import { PhrasesEffect } from './store/phrase/phrase.effect';
import { PassagesEffect } from './store/passage/passage.effect';
import { CourseWorkbookResolver } from './resolvers/course-workbook.resolver';
import { AllCourseWorkbookResolver } from './resolvers/all-course-workbook.resolver';
import { ActivityInitResolver } from './resolvers/activity-init.resolver';
import { LibraryWorkbooksResolver } from './resolvers/library-workbooks.resolver';
import { SetCourseCourseResolver, SetCourseResolver } from './resolvers/set-course.resolver';
import { WorkbookResolver } from './resolvers/workbook.resolver';
import { WorkbookActivityResolver } from './resolvers/workbook-activity.resolver';
import { PlanResolver } from './resolvers/plan.resolver';
import { AllSentencesResolver } from './resolvers/all-sentences.resolver';
import { WorkbookPassagesResolver } from './resolvers/workbook-passages.resolver';
import { WorkbookPhrasesResolver } from './resolvers/workbook-phrases.resolver';
import { WorkbookSentencesResolver } from './resolvers/workbook-sentences.resolver';
import { AllPassagesResolver } from './resolvers/all-passages.resolver';
import { AllFilesResolver } from './resolvers/all-files.resolver';
import { FilesEffect } from './store/file/file.effect';
import { WorkbookFilesResolver } from './resolvers/workbook-files.resolver';
import { MainCourseLessonsResolver } from './resolvers/main-course-lessons.resolver';
import { AllLessonStepsResolver } from './resolvers/all-lesson-steps.resolver';
import { LessonPlanResolver } from './resolvers/lesson-plan.resolver';
import { CanvasResolver } from './resolvers/canvas.resolver';
import { AllSharingCollectionsResolver } from './resolvers/all-sharing-collections.resolver';
import { SharingEffect } from './store/sharing/sharing.effect';
import { SharingService } from './services/sharing.service';
import { AccountPaymentResolver } from './resolvers/account-payment.resolver';
import { AccountPlanResolver } from './resolvers/account-plan.resolver';
import { AccountSubscriptionResolver } from './resolvers/account-subscription.resolver';
import {TileActivityService} from './services/tile-activity.service';
import { WordPairingsResolver } from './resolvers/word-pairings.resolver';
import { WorkbookActivityEffect } from './store/workbook-activity/workbook-activity.effect';
import { CourseWorkbookActivityService } from './services/course-workbook-activity.service';
import { LessonPlanStepResolver } from './resolvers/lesson-plan-step.resolver';
import { AllCourseWorkbooksResolver } from './resolvers/all-course-workbooks.resolver';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { PaginatePipe } from './pipes/paginate.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { DebounceClickDirective } from '../activities/directives/debounce-click.directive';
import { WorkbookActivityLessonStepService } from './services/workbook-activity-lesson-step.service';
import { WorkbookActivityDataFixResolver } from './resolvers/workbook-activity-data-fix.resolver';
import { FeedbackEffect } from './store/feedback/feedback.effect';
import { FeedbackService } from './services/feedback.service';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        StoreModule.forFeature('whizzimo', fromWhizzimo.reducers),
        EffectsModule.forFeature([
            DummyDataEffect,
            PlanEffect,
            CoursesEffect,
            SubscriptionEffect,
            CourseWorkbookEffect,
            WorkbooksEffect,
            SetCourseEffect,
            SetttingsEffect,
            ActivityEffect,
            AccountEffect,
            SentencesEffect,
            PhrasesEffect,
            PassagesEffect,
            WordsEffect,
            LessonPlansEffect,
            EditLessonPlansEffect,
            FilesEffect,
            SharingEffect,
            WorkbookActivityEffect,
            FeedbackEffect
        ]),
        DragDropModule,
        ScrollingModule,
        FlexLayoutModule,
        FontAwesomeModule,
        DndModule.forRoot(),
        AngularDraggableModule,
        MaterialModule,
        NgScrollbarModule,
        CreditCardDirectivesModule
        // NgxExtendedPdfViewerModule
    ],
    exports: [
        CommonModule,
        ConfirmationDialogComponent,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HeaderComponent,
        HeaderWorkbookBuilderComponent,
        HeaderSettingsComponent,
        CourseWorkBooksComponent,
        WorkbookCardsComponent,
        CreateWorkbookOptionsComponent,
        WordBottomSheetComponent,
        TilePairingModalComponent,
        LogOutModalComponent,
        SelectPlanModalComponent,
        DemoModalComponent,
        DemoScreenDialogComponent,
        ColorMenuComponent,
        WorkBooksComponent,
        WorkbookLibraryComponent,
        AddWorkBooksComponent,
        SupportComponent,
        TutorialButtonComponent,
        DeleteCollectionDialogComponent,
        RouterModule,
        ValidationComponent,
        DragDropModule,
        ScrollingModule,
        TileTextPipe,
        CallbackPipe,
        SafePipe,
        SortPipe,
        TileBankComponent,
        SpellingAreaComponent,
        AngularDraggableModule,
        BumpingTilesComponent,
        MaterialModule,
        ManageCourseDialogComponent,
        AddCourseDialogComponent,
        ViewWordsDialogComponent,
        CourseWorkbookEditComponent,
        CourseWorkbookEditWordsComponent,
        CourseWorkbookEditTilesComponent,
        CourseWorkbookEditWordTilesComponent,
        CourseWorkbookEditWorkbookItemsComponent,
        CourseWorkbookEditMenuComponent,
        AddSavedSettingsDialogComponent,
        LessonPlanCardsComponent,
        // NgScrollbarModule,
        CourseWorkbookMenuComponent,
        CourseListDialogComponent,
        LessonPlanCardsComponent,
        DragDropCardsComponent,
        // NgxExtendedPdfViewerModule,
        CourseWorkbookEditComponent,
        SettingsHeaderComponent,
        AddCollectionDialogComponent,
        AddImportCollectionDialogComponent,
        ViewSharingListDialogComponent,
        PaginatePipe,
        FilterPipe,
        DebounceClickDirective,
        FlexLayoutModule
    ],
    declarations: [
        CourseWorkbookEditComponent,
        CourseWorkbookEditWordsComponent,
        CourseWorkbookEditTilesComponent,
        CourseWorkbookEditWordTilesComponent,
        CourseWorkbookEditWorkbookItemsComponent,
        CourseWorkbookEditMenuComponent,
        HeaderComponent,
        HeaderWorkbookBuilderComponent,
        DeleteCollectionDialogComponent,
        HeaderSettingsComponent,
        HeaderActivitiesComponent,
        HeaderLessonPlansComponent,
        CourseWorkBooksComponent,
        WorkBooksComponent,
        WorkbookCardsComponent,
        CreateWorkbookOptionsComponent,
        WordBottomSheetComponent,
        TilePairingModalComponent,
        LogOutModalComponent,
        SelectPlanModalComponent,
        DemoModalComponent,
        ColorMenuComponent,
        WorkbookLibraryComponent,
        AddWorkBooksComponent,
        SupportComponent,
        TutorialDialogComponent,
        TutorialButtonComponent,
        ValidationComponent,
        CourseDialogComponent,
        CourseWorkbookMenuComponent,
        CourseWorkbookSettingsMenuComponent,
        WorkbookLibraryMenuComponent,
        WorkbookMenuComponent,
        SubscriptionMenuComponent,
        AddCardComponent,
        AddCardMenuComponent,
        SamePlanMenuComponent,
        CancelSubscriptionComponent,
        SelectPlanComponent,
        SuccessfulComponent,
        AddWorkbookDialogComponent,
        TileTextPipe,
        CallbackPipe,
        SafePipe,
        SortPipe,
        TileBankComponent,
        SpellingAreaComponent,
        BumpingTilesComponent,
        ManageCourseDialogComponent,
        AddCourseDialogComponent,
        ViewWordsDialogComponent,
        AddSavedSettingsDialogComponent,
        LessonPlanCardsComponent,
        CourseListDialogComponent,
        DragDropCardsComponent,
        AddSavedSettingsDialogComponent,
        SettingsHeaderComponent,
        BreadcrumbComponent,
        TileComponent,
        AddCollectionDialogComponent,
        AddImportCollectionDialogComponent,
        ConfirmationDialogComponent,
        DemoScreenDialogComponent,
        LessonPlanWarningDialogComponent,
        ViewSharingListDialogComponent,
        SimultaneousLoginDialogComponent,
        MessageDialogComponent,
        PaginatePipe,
        FilterPipe,
        DebounceClickDirective
    ],
    providers: [
        ApiService,
        WordlistService,
        StripeService,
        UserService,
        ActivityTypeService,
        ActivityService,
        SharingService,
        TileActivityService,
        CourseWorkbookActivityService,
        WorkbookActivityLessonStepService,
        FeedbackService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true
        },
        AllCoursesResolver,
        AllSubscribedCoursesResolver,
        AllMyLibraryWorkbooksResolver,
        MainCourseResolver,
        MainCourseWorkbooksResolver,
        SettingsResolver,
        CourseWorkbookResolver,
        ActivityInitResolver,
        LibraryWorkbooksResolver,
        SetCourseCourseResolver,
        WorkbookResolver,
        AllPhrasesResolver,
        AllPassagesResolver,
        AllSentencesResolver,
        AllFilesResolver,
        WorkbookPassagesResolver,
        WorkbookPhrasesResolver,
        WorkbookSentencesResolver,
        WorkbookFilesResolver,
        SetCourseResolver,
        MainCourseLessonsResolver,
        AllLessonStepsResolver,
        AllSharingCollectionsResolver,
        AccountPaymentResolver,
        AccountPlanResolver,
        AccountSubscriptionResolver,
        WordPairingsResolver,
        WorkbookActivityResolver,
        LessonPlanStepResolver,
        PlanResolver,
        AllCourseWorkbookResolver,
        LessonPlanResolver,
        AllCourseWorkbooksResolver,
        DatePipe,
        WorkbookActivityDataFixResolver,
        CanvasResolver
    ]
})

export class CoreModule {
}
